<template>
  <div>
    <portal to="title-page">
      <h1>Crear extra</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          mdi-plus
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small class="ml-auto" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
        <v-text-field
          label="Idioma"
          :prepend-icon="mdiFlag"
          :value="language ? language.name : ''"
          readonly
        ></v-text-field>
          <v-row >
            <v-col cols="12" md="12" class="py-3">
              <!-- <v-divider></v-divider> -->
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="extra.name"
                :rules="[rules.required]"
                label="Nombre"
                :prepend-icon="mdiText"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select
                v-if="categoriesExtras != null"
                v-model="extra.category"
                :items="categoriesExtras"
                item-text="name"
                return-object
                prepend-icon="mdi-currency-usd"
                :rules="[v => !!v || 'La Categoria es requerida']"
                label="Seleccione la categoria"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" class="">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="12" class="">
              <label>Local</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.localAdult"
                :rules="[rules.required,rules.number]"
                :label="isATV() ? `Precio adulto single` : `Precio adulto`"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="isATV()" cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.localAdultShared"
                :rules="[rules.required,rules.number]"
                label="Precio adulto shared"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.localChild"
                :rules="[rules.required,rules.number]"
                label="Precio niño"
                prefix="$"
                required
                :prepend-icon="mdiHumanMaleChild"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.localElderly"
                :rules="[rules.required,rules.number]"
                label="Precio adulto mayor"
                prefix="$"
                :prepend-icon="mdiHumanCane"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.localInfant"
                :rules="[rules.required,rules.number]"
                label="Precio Infante"
                prefix="$"
                :prepend-icon="mdiBabyCarriage"
                required
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="12" class="">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="12" class="">
              <label>Nacional</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.nationalAdult"
                :rules="[rules.required,rules.number]"
                :label="isATV() ? `Precio adulto single` : `Precio adulto`"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="isATV()" cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.nationalAdultShared"
                :rules="[rules.required,rules.number]"
                label="Precio adulto shared"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.nationalChild"
                :rules="[rules.required,rules.number]"
                label="Precio niño"
                prefix="$"
                required
                :prepend-icon="mdiHumanMaleChild"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.nationalElderly"
                :rules="[rules.required,rules.number]"
                label="Precio adulto mayor"
                prefix="$"
                :prepend-icon="mdiHumanCane"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.nationalInfant"
                :rules="[rules.required,rules.number]"
                label="Precio Infante"
                prefix="$"
                :prepend-icon="mdiBabyCarriage"
                required
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="12" class="">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="12" class="">
              <label>Extranjero</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.foreignAdult"
                :rules="[rules.required,rules.number]"
                :label="isATV() ? `Precio adulto single` : `Precio adulto`"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="isATV()" cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.foreignAdultShared"
                :rules="[rules.required,rules.number]"
                label="Precio adulto shared"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.foreignChild"
                :rules="[rules.required,rules.number]"
                label="Precio niño"
                prefix="$"
                required
                :prepend-icon="mdiHumanMaleChild"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.foreignElderly"
                :rules="[rules.required,rules.number]"
                label="Precio adulto mayor"
                prefix="$"
                :prepend-icon="mdiHumanCane"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.foreignInfant"
                :rules="[rules.required,rules.number]"
                label="Precio Infante"
                prefix="$"
                :prepend-icon="mdiBabyCarriage"
                required
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="12" class="py-3">
              <!-- <v-divider></v-divider> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" class="py-0">
              <p >Descripcion</p>
              <vue-editor
                id="description"
                :class="extra.description != null && extra.description == '' ? 'error' : ''"
                v-model="extra.description"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <p >Importante</p>
              <vue-editor
                id="important"
                :class="extra.important != null && extra.important == '' ? 'error' : ''"
                v-model="extra.important"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <p >Requerimientos</p>
              <vue-editor
                id="requirements"
                :class="extra.requirements != null && extra.requirements == '' ? 'error' : ''"
                v-model="extra.requirements"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
            <v-col cols="12" md="12" class="pt-5">
              <v-btn
                :disabled="disableBtnAdd"
                color="primary"
                class="mr-4"
                @click="validateForm"
              >
                <v-icon>mdi-content-save</v-icon>
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- <v-card-actions>
      </v-card-actions> -->
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Language from "@/models/Language.js";
import Extra from "@/models/Extra.js";
import CategoryExtra from "@/models/CategoryExtra.js";
//import Vue from "vue";
//import firebase from 'firebase/app';
//import 'firebase/firestore';
import { mdiHumanMaleChild, mdiBabyCarriage, mdiHumanMale, mdiClose, mdiArrowLeft, mdiFlag, mdiText } from '@mdi/js';
import { mdiHumanCane  } from '@/assets/icons/mdi-icons-extended.js';
import { VueEditor } from "vue2-editor";

export default {
  name: "RateCreate",
  components: { VueEditor },
  data() {
    return {
      mdiHumanMaleChild:mdiHumanMaleChild,
      mdiBabyCarriage:mdiBabyCarriage,
      mdiHumanCane:mdiHumanCane,
      mdiHumanMale:mdiHumanMale,
      mdiClose:mdiClose,
      mdiArrowLeft:mdiArrowLeft,
      mdiFlag:mdiFlag,
      mdiText:mdiText,
      ////////////////////////////////////////////////////////////////////////////
      languageModel:new Language,
      extraModel:new Extra,
      categoryExtraModel:new CategoryExtra,
      ////////////////////////////////////////////////////////////////////////////
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      ////////////////////////////////////////////////////////////////////////////
      languages:null,
      categoriesExtras:null,
      validForm: true,
      validFormInit: true,
      rules : {
        required : v => v && v.length > 0 || 'Campo requerido',
        number : v => {
          const pattern = /^[0-9]+([.][0-9]+)?$/
          return pattern.test(v) || 'Numero invalido'
        },
      },
      language:null,
      extra:{
        name:'',
        description:null,
        requirements:null,
        important:null,
        adult:'',
        elderly:'',
        child:'',
        infant:'',
        category:null,
      },
      snackbar:{
        show:false,
        color:'',
        message:''
      }
    }
  },
  methods:{
    validateForm() {
      this.validFormInit = false;
      this.extra.description = this.extra.description == null ? '' : this.extra.description;
      this.extra.important = this.extra.important == null ? '' : this.extra.important;
      this.extra.requirements = this.extra.requirements == null ? '' : this.extra.requirements;
      if (this.$refs.form.validate()) {
        let loading = this.$loading.show();
        //let data = {name:this.extra.name, status:'inactive', category:this.extra.category.name, categoryId:this.extra.category.id};
        this.extraModel.add({
          name:this.extra.name,
          localAdult:this.extra.localAdult,
          //localElderly:this.extra.localElderly,
          localChild:this.extra.localChild,
          //localInfant:this.extra.localInfant,
          nationalAdult:this.extra.nationalAdult,
          //nationalElderly:this.extra.nationalElderly,
          nationalChild:this.extra.nationalChild,
          //nationalInfant:this.extra.nationalInfant,
          foreignAdult:this.extra.foreignAdult,
          //foreignElderly:this.extra.foreignElderly,
          foreignChild:this.extra.foreignChild,
          //foreignInfant:this.extra.foreignInfant,
          status:'inactive',
          panels:[],
          order:1,
          category:this.extra.category.name,
          categoryId:this.extra.category.id
        }).then(docRef => {
          docRef.collection('extraLanguages').doc(this.extra.language).set({
            name:this.extra.name,
            description:this.extra.description,
            important:this.extra.important,
            requirements:this.extra.requirements,
            language:this.languages.es.name
          })
          .then(() => {
            loading.hide();
            this.showSnackbarSuccess('El extra se agrego correctamente');
            this.$router.push({path:'/backend/admin/extras'})
          })
          .catch(error => {
            loading.hide();
            console.log(error);
            this.showSnackbarError('Error al crear el lenguaje del extra');
          })
        })
        .catch( error=> {
          console.log(error);
          loading.hide();
          this.showSnackbarError('Error al crear el extra');
        })
      }
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'green';
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'red';
    },
    isATV() {
      return this.extra.category && this.extra.category.id == '35GgLDUefBqgKGSqiMhv'
    }
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'admin')
  },
  computed:{
    disableBtnAdd : function() {
      if (this.validFormInit) {
        return false;
      }
      if (!this.extra.description || !this.extra.important || !this.extra.requirements || !this.validForm) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.languageModel.getAll().onSnapshot(snap => {
      this.languages = {};
      snap.forEach( doc => {
        this.languages[doc.id] = doc.data();
      })
      this.language = this.languages.es;
      this.extra.language = 'es';
    })
    this.categoryExtraModel.getAll().onSnapshot(snap => {
      this.categoriesExtras = [];
      snap.forEach(doc => {
        this.categoriesExtras.push(doc.data());
      })
    })
  },
}
</script>
<style>
  #app .quillWrapper.error {
    background-color: transparent !important;
  }
  .quillWrapper.error > div {
    border-color: #ff5252;
    background-color: transparent;
  }
</style>
